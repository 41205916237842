.MuiTabs-root {
  overflow: hidden;
}

.MuiTabPanel-root {
  overflow-y: auto;
}

code {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0px 2px;
}

.terminal code {
  border: none;
  white-space: pre;
}

.terminal {
}

.terminal-container {
  display: flex;
  flex-direction: column-reverse;
  overflow: scroll;
  height: 100%;
}

.terminal-output {
  white-space: pre-wrap;
  font-family: monospace;
}
/* .terminal-stdout {} */
.terminal-stderr { color: red; }
